<template>
  <v-container
    fill-height
    justify-center
  >
    <v-card
      max-width="500"
      rounded="xl"
    >
      <v-card-title class="justify-center primary--text text-h5">
        Connexion
      </v-card-title>

      <v-card-text class="text-center">
        <span>Connectez-vous avec vos </span>

        <span class="font-weight-bold">identifiants Condorcet </span>

        <span>pour continuer.</span>

        <v-img
          :src="require('@/assets/undraw_Login_re_4vu2.svg')"
          :width="250"
          class="mt-6 mx-auto"
        ></v-img>
      </v-card-text>

      <v-card-actions>
        <v-btn
          block
          large
          color="primary"
          @click="login"
          rounded
        >
          Se connecter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AdminLogin',

  methods: {
    ...mapActions(['login']),
  },
};
</script>
